import React, { useEffect } from 'react';
import T from 'prop-types';
import styled, { createGlobalStyle } from 'styled-components';
import { graphql } from 'gatsby';
import { Masonry } from 'masonic';
import get from 'lodash.get';
import { media } from '@devseed-ui/theme-provider';

import Layout from '../../components/layout';
import {
  Inpage,
  InpageHeader,
  InpageHeaderInner,
  InpageHeadline,
  InpageTitle,
  InpageBody,
  InpageLead
} from '../../styles/inpage';
import {
  SectionBody,
  SectionCardGroup,
  sectionCardGroupHeaderGrid,
  SectionHeader,
  SectionHeadline,
  SectionTitle
} from '../../styles/section';
import { CardTeam } from '../../components/cards';

import useMediaAware from '../../utils/media-query-aware-hook';
import { MasonryItemsListElement } from '../../styles/masonry';

const teamGroups = [
  {
    id: 'biz-op',
    title: 'Business Strategy & Operations',
    description: 'Group description'
  },
  {
    id: 'engineering',
    title: 'Engineering',
    description: 'Group description'
  },
  {
    id: 'design-dev',
    title: 'Design & Development',
    description: 'Group description'
  },
  {
    id: 'other',
    title: 'Other',
    description:
      "People in this group have no group property defined in the markdown's frontmatter."
  }
];

const SectionCardGroupTeam = styled(SectionCardGroup)`
  &::before {
    ${media.largeUp`
      display: none;
    `}
  }

  &::after {
    ${media.largeUp`
      grid-column-start: content-7;
    `}
  }
`;

const TeamSectionHeader = styled(SectionHeader)`
  ${media.largeUp`
    position: sticky;
    top: 1rem;
    align-self: start;
  `}
`;

// For the position sticky to work, the Page wrapper (in Layout) can't have
// overflow hidden.
const TeamGlobalStyles = createGlobalStyle`
  .app-page {
    overflow: visible;
  }
`;

const Team = ({ data, location }) => {
  const team = data.allTeam.nodes;

  return (
    <Layout location={location} title='Team'>
      <TeamGlobalStyles />
      <Inpage as='section'>
        <InpageHeader>
          <InpageHeaderInner>
            <InpageHeadline>
              <InpageTitle>
                A passionate group of people addressing global challenges
              </InpageTitle>
            </InpageHeadline>
            <InpageLead>
              <p>
                We are artists, rocket scientists, teachers, designers,
                engineers, data scientists and public health researchers working
                together for a future-positive world.
              </p>
            </InpageLead>
          </InpageHeaderInner>
        </InpageHeader>
        <InpageBody>
          {teamGroups.map((group) => {
            const people = team.filter(
              (t) => (t.frontmatter.group || 'other') === group.id
            );

            // Only render a group if there are people in it.
            return people.length ? (
              <TeamGroup
                key={group.id}
                id={group.id}
                title={group.title}
                description={group.description}
                people={people}
              />
            ) : null;
          })}
        </InpageBody>
      </Inpage>
    </Layout>
  );
};

export default Team;

Team.propTypes = {
  data: T.object,
  location: T.object
};

// // // // // // // // // // // // // // // // // // // // // // // // // // //
// TeamGroups - Auxiliary components                                          //
//                                                                            //

const teamMasonryRenderer = ({ index, data }) => {
  const { title, expertise, teaser } = data.frontmatter;

  const image = get(data.frontmatter, 'media.card.url.childImageSharp.fluid');

  return (
    <CardTeam
      key={data.id}
      name={title}
      slug={data.slug}
      description={teaser}
      cardImage={image || null}
      expertise={expertise}
      location={null}
      index={index + 1}
    />
  );
};

function TeamGroup(props) {
  const [ref, media] = useMediaAware();

  // In this case our reference is the body, and not a specific element.
  useEffect(() => {
    ref(document.body);
  }, [ref]);

  const { id, title, people } = props;

  return (
    <SectionCardGroupTeam key={id}>
      <TeamSectionHeader grid={sectionCardGroupHeaderGrid}>
        <SectionHeadline>
          <SectionTitle>{title}</SectionTitle>
        </SectionHeadline>
      </TeamSectionHeader>
      <SectionBody>
        <Masonry
          overscanBy={Infinity}
          items={people}
          columnGutter={
            media
              ? media.isXsmallDown()
                ? 16
                : media.isLargeDown()
                ? 32
                : 48
              : null
          }
          columnCount={media ? (media.isSmallDown() ? 1 : 2) : null}
          columnWidth={288}
          as={MasonryItemsListElement}
          itemAs='li'
          itemKey={(item) => item.id}
          render={teamMasonryRenderer}
        />
      </SectionBody>
    </SectionCardGroupTeam>
  );
}

TeamGroup.propTypes = {
  id: T.string,
  title: T.string,
  people: T.array
};
//                                                                            //
// END   TeamGroups - Auxiliary components                              //
// // // // // // // // // // // // // // // // // // // // // // // // // // //

export const pageQuery = graphql`
  query {
    allTeam(
      filter: { archived: { eq: false }, published: { eq: true } }
      sort: {
        order: [DESC, ASC]
        fields: [frontmatter___weight, frontmatter___title]
      }
    ) {
      nodes {
        id
        slug
        frontmatter {
          title
          teaser
          expertise
          group
          media {
            card {
              url {
                childImageSharp {
                  fluid(maxWidth: 320, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
